.App {
  /* text-align: center; */
  margin: auto;

}

.logo {
  display: block;
  width: 50%;
  margin-top: 20%;

}

.text {
  font-size: 100%;
  margin-top: 3%;
  color: white;
}


/* Style the container */
.jumping-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

@media screen and (max-width: 480px) {
  .logo {
    margin-top: 50%;

  }

  .jumping-dots {
    margin-top: 20%;
  }
}

/* Style the dots */
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin: 0 5px;
  animation: jump 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0.1s;
  background-color: white;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
  background-color: rgba(110, 142, 134, 0.5471774193548387);
}

.dot:nth-child(3) {
  animation-delay: 0.3s;
  background-color: #6e8e86;
}

/* Create the animation */
@keyframes jump {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */